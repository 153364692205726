<template>
  <div id="app">
    <b-row>
      <b-col sm="4">
        <b-form-group>
          <label for="Title">Change Status to</label>

          <b-form-select
            @input="getSeatsLay"
            v-model="status_id"
            :options="status_options"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group>
          <label for="Title">Event List with Close Seating</label>

          <b-form-select
            v-model="event_id"
            :options="event_options"
            v-on:change="audupdated"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="4" v-if="event_id">
        <b-form-group>
          <label for="Title">Select Schedule for the Eevent</label>

          <b-form-select
            @input="getSeatsLay"
            v-model="schedule_id"
            :options="schedule_options"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="4" v-for="(item, index) in seatLoayoutData" :key="index">
        <b-form-group>
          <label for="Title">{{ item.title }} Seats</label>
          <multiselect
            v-model="selectedSeats[index]"
            :options="item.rows"
            track-by="seat_id"
            label="name"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :select-label="''"
            :deselect-label="'Click to remove'"
            :selectedLabel="'Selected'"
          ></multiselect>
        </b-form-group>
      </b-col>

      <!-- <b-col sm="12" v-if="schedule_id">
        <b-form-textarea
          :value="arrToText"
          class="mb-2"
          @update="textToArr"
        ></b-form-textarea>
        <h5>
          <b-badge
            v-for="(item, index) in listArr"
            :key="index"
            pill
            variant="primary"
            class="mr-2"
            >{{ item }}</b-badge
          >
        </h5>
      </b-col> -->
    </b-row>
    <b-button
      v-if="selectedSeats.length > 0"
      @click="handleSubmit()"
      size="sm"
      variant="success"
    >
      SUBMIT
    </b-button>
  </div>
</template>

<script>
import MasterService from "@/services/MasterService";
import UploadService from "@/services/UploadService";
import { Switch as cSwitch } from "@coreui/vue";
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      status_options: [
        { value: 1, text: "Block" },
        { value: 2, text: "Un Block" },
      ],
      status_id: 1,
      schedule_options: [],
      schedule_id: null,
      listArr: [],
      arrToText: "",
      event_id: null,
      event_options: [],
      seatLoayoutData: [],
      allBlockedSeats: [],
      selectedSeats: [],
      event_info: {
        country_id: null,
        e_public: 1,
        e_name: "",
        e_s_description: "",
        e_description: "",
        e_image: "",
        es_date: "",
        ee_date: "",
        e_book_type: "null",
        terms_condition: "",
        booking_open: true,
        is_unlisted: true,
        is_free: true,
        ec_id: "1",
        additional_information: "null",
        rating: "",
        e_genreData: [
          {
            e_genreData: "",
          },
        ],
        cast_crew: [
          {
            cc_name: "cc_name",
            cc_type: "cc_type",
            cc_picture: "cc_picture",
          },
        ],
        e_banners: [
          {
            e_banners: "",
          },
        ],
        e_videos: [
          {
            e_videos: "",
          },
        ],
        organiser_details: [
          {
            od_name: "",
            od_sdescription: "",
            od_description: "",
            od_logo: "",
            od_email: "",
            od_mobile: "",
          },
        ],
        sponsor_details: [
          {
            event_id: "event_id",
            sd_name: "sd_name",
            sd_sdescription: "sd_sdescription",
            sd_description: "sd_description",
            sd_logo: "sd_logo",
            sd_email: "sd_email",
            sd_mobile: "123456789",
          },
        ],
        venue: [
          {
            event_id: "1",
            ev_name: "",
            ev_address: "",
            ev_lat: "",
            ev_long: "",
            ev_city: "",
            ev_country: "",
            auditorium: [
              {
                event_venue_id: "1",
                a_name: "",
                a_description: "",
                a_row: "0",
                a_col: "0",
                booking_type: "null",
                max_capacity: "0",
                area_type: [
                  {
                    area_name: "",
                    max_capacity: "",
                    seat_type: [
                      {
                        auditorium_id: "1",
                        st_name: "",
                        price: "",
                        currency: "",
                      },
                    ],
                  },
                ],
                seat_layout: [],
                schedule: [
                  {
                    auditorium_id: "1",
                    event_id: "1",
                    es_date: "",
                    es_time: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  },
  components: {},
  beforeMount() {
    MasterService.closeSeatLayoutEventList()
      .then((response) => {
        // console.log(response.data.List, "countryyyyy");
        // console.log("object :>> ", response.data.data);
        this.event_options = response.data.data;
        console.log("event_options :>> ", this.event_options);
      })
      .catch((error) => {
        console.log("Catch on Getting Country Event Error: ", error);
      });
  },

  mounted() {},

  methods: {
    audupdated(value) {
      console.log("object :>> ", value);
      if (value) {
        MasterService.getAudSchedule(value)
          .then((response) => {
            // console.log(response.data.List, "countryyyyy");
            // console.log("object :>> ", response.data.data);
            this.schedule_options = response.data.data;
            console.log("schedule_options :>> ", this.schedule_options);
          })
          .catch((error) => {
            console.log("Catch on Getting Country Event Error: ", error);
          });
      }
    },
    textToArr(value) {
      this.listArr = value
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item.length > 0);
    },

    getSeatsLay() {
      this.seatLoayoutData = [];
      this.allBlockedSeats = [];
      this.selectedSeats = [];
      this.listArr = [];
      MasterService.GetSeatLayout(this.schedule_id)
        .then((response) => {
          this.seatLoayoutData = response.data.data;
          this.allBlockedSeats = response.data.reserveSeatData;

          console.log(this.allBlockedSeats, "this.allBlockedSeats");
          for (let prop in this.seatLoayoutData) {
            if (this.seatLoayoutData[prop] && this.seatLoayoutData[prop].rows) {
              if (this.status_id == 1) {
                this.seatLoayoutData[prop].rows = this.seatLoayoutData[
                  prop
                ].rows.filter(function (obj) {
                  return obj.status != "2";
                });
              } else if (this.status_id == 2) {
                this.seatLoayoutData[prop].rows = this.seatLoayoutData[
                  prop
                ].rows.filter(function (obj) {
                  let checkIsManualyBlocked =
                    response.data.reserveSeatData.filter((c) => {
                      return c.seat_id == obj.seat_id;
                    });

                  if (
                    checkIsManualyBlocked.length > 0 &&
                    checkIsManualyBlocked[0].isManualBlocked == "1"
                  ) {
                    obj["isManualBlocked"] = 1;
                  } else {
                    obj["isManualBlocked"] = 0;
                  }

                  return obj.status != "1" && obj.isManualBlocked == "1";
                });
              } else {
              }
            }
          }
        })
        .catch((error) => {
          console.log("Catch on Getting Country Event Error: ", error);
        });
    },

    handleSubmit() {
      this.listArr = [];
      for (let i = 0; i <= this.selectedSeats.length; i++) {
        let newItem = this.selectedSeats[i];

        if (newItem) {
          for (let seat of newItem) {
            this.listArr.push(seat.name);
          }
        }
      }
      console.log(this.listArr, "listArr");
      //  return false;
      if (this.listArr.length > 0 && this.schedule_id) {
        // blockScheduleSeats(this.schedule_id, this.listArr)
        MasterService.blockScheduleSeats(this.schedule_id, this.status_id, {
          seats: this.listArr,
        })
          .then((response) => {
            console.log("response.data.message :>> ", response.data.message);
            this.getSeatsLay();
            //alert(response.data.message);
            // console.log(response.data.List, "countryyyyy");
            // console.log("object :>> ", response.data.data);
            // this.schedule_options = response.data.data;
            // console.log("schedule_options :>> ", this.schedule_options);
          })
          .catch((error) => {
            console.log("Catch on Getting Country Event Error: ", error);
          });
      }
    },
  },
};
</script>

<style>
.card-header {
  background: none !important;
}

.status-alignment {
  margin-top: 1.3rem;
}
.rmv-btn-alignment {
  margin-top: 2.5rem;
}
</style>
